.inputStyle {
  color: white !important;
  width: 1000px !important;
  padding-top: 10px !important;
  overflow-y: auto !important;
  line-height: initial !important;
}

.success_btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.main_container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.glow {
  position: absolute;
  left: 0;
  right: 0;
}

.opa_show {
  visibility: visible;
}

.opa_hide {
  visibility: hidden;
}

.counter_container {
  position: fixed;
  left: 15%;
  right: 0;
  top: 0px;
  width: 70%;
}

.instruction {
  position: fixed;
  top: 15%;
  left: 5%;
}

.quit_btn {
  border: none !important;
}
