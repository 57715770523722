.roundButton {
    width: 246px;
    height:51px;
    border-radius: 25.5px;
    background-color: var(--text-color);
    transition: all .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roundButtonText {
    line-height: 45px;
    margin: 0%;
    padding: 0%;
    color: var(--bgColor);
    font-size: 22px;
}

.roundButtonText:hover {
    opacity: 0.8;
    color: var(--bgColor);

}

.playicon {
margin-left: 20px;
}