.App {
  text-align: center;
}

/* Font of LearningSession */
.same_font {
  font-size: 0.81rem;
  font-weight: 400;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.top_right_menu {
  font-size: 0.81rem;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
._menuIcon {
  height: 30px;
  width: 30px;
}
._desktopMenu {
  background-color: var(--bgColor);
  width: "100%";
  height: "100%";
}
/* main page options */

/* .mainoptions { */
/* height:125px; */
/* width: 125px; */
/* padding: 35px; */
/* background-color: var(--bgColor); */
/* } */

/* options text */

.renderedtext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.33em;
  color: var(--bgColor);
  opacity: 0.8;
}

.big_button {
  box-sizing: border-box;
  width: 245px;
  height: 51px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
}

.small_button {
  box-sizing: border-box;
  width: 245px;
  height: 51px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 5px;
  color: var(--bgColor) !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.1em;
}
/* width */

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.animation {
  opacity: 1;
  transition: hide 0.5s linear 1;
}

@keyframes hide {
  to {
    opacity: 0;
  }
}

.left_count {
  margin-left: 30px;
}
.custom-input-box {
  border-bottom: 0.5px solid black;
  box-shadow: 0 0px 0 0 black;
}
.custom-input-box :focus {
  border-bottom: 0px solid black;
}
.right_count {
  margin-right: 30px;
}

textarea.md-textarea.form-control.form-control-lg.text-center,
input.form-control.form-control-lg.text-center {
  line-height: 1 !important;
  /* font-size: 1.5rem !important; */
  line-height: 1.5 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
textarea.md-textarea.form-control.form-control-lg.text-center2,
input.form-control.form-control-lg.text-center2 {
  line-height: 1 !important;
  /* font-size: 2rem !important; */
  line-height: 1.5 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.form-control {
  color: var(--text-color);
}

nav {
  background: #fff;
}

.fixed-top {
  z-index: 9999 !important;
}
/* white theme */

a.nav-link.Ripple-parent {
  color: var(--text-color) !important;
  font-weight: 400 !important;
}
/* this is the animation of the logo  */

.os-phrases h2,
.flash_anim h2,
.flash_anim h1 {
  font-weight: 200;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: fixed;
  left: 1%;
  right: 0;
  top: 47%;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  color: hsla(0, 0%, 0%, 0) !important;
  cursor: pointer;
  z-index: 9000;
}

.blinking {
  animation-name: blinkingText;
  animation-duration: 1s;
  animation-delay: 8s !important;
  animation-iteration-count: 1;
  animation-direction: normal;
}
/* white theme */

@keyframes blinkingText {
  0% {
    color: var(--text-color);
    opacity: 0;
  }
  49% {
    color: var(--text-color);
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: var(--text-color);
  }
}

.flash_anim h2:nth-child(1) {
  animation-name: FadeIns;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: none;
  cursor: none;
}

.flash_anim h2:nth-child(2) {
  animation-name: FadeIns;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: none;
  cursor: none;
}

.os-phrases h2:nth-child(1) {
  animation-name: FadeIns;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: none;
  cursor: none;
}

.os-phrases h2:nth-child(2) {
  animation-name: FadeIns;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 3.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: none;
  cursor: none;
}

.os-phrases h2:nth-child(3) {
  animation-name: FadeIns;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 5.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: none;
  cursor: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* white theme */

@keyframes FadeIn {
  0% {
    opacity: 0;
    text-shadow: 0 0 1px var(--text-color);
  }
  100% {
    opacity: 0.8;
    text-shadow: 0 0 1px var(--text-color);
  }
}
/* white theme */

@keyframes FadeIns {
  0% {
    opacity: 0;
    text-shadow: 0 0 1px var(--text-color);
    pointer-events: none;
  }
  50% {
    opacity: 0.8;
    text-shadow: 0 0 1px var(--text-color);
    pointer-events: none;
  }
  100% {
    opacity: 0;
    text-shadow: 0 0 1px var(--text-color);
    pointer-events: none;
  }
}
/* white theme */

.sp {
  animation: blink-empty 1s infinite;
  background-image: linear-gradient(var(--text-color), var(--text-color));
  background-position: 1px center;
  background-repeat: no-repeat;
  background-size: 1px 1.2em;
}

textarea {
  padding-top: 25px !important;
  padding-bottom: 0 !important;
}

#hamburgher {
  border: none !important;
  box-shadow: none !important;
}
/* white theme */

.scrollbar-container {
  background: white !important;
  color: var(--text-color) !important;
  left: 0;
  top: 0;
  position: fixed !important;
  z-index: 9999 !important;
  border-right: 1px solid var(--text-color);
  width: 250px;
}

.scrollbar-container ul li {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #efefef;
}
/* white theme */

.scrollbar-container ul li a,
.scrollbar-container ul li {
  color: white !important;
}

.scrollbar-container ul li:first-child a {
  font-size: 16px;
  font-weight: 200;
  color: white !important;
}

.scrollbar-container ul li:first-child {
  font-size: 24px;
  font-weight: 400;
}

.scrollbar-container ul li:hover {
  background-color: #efefef;
}

.scrollbar-container ul li:first-child {
  background-color: #333333 !important;
}

.ready {
  cursor: pointer;
  position: fixed;
  top: 47%;
  left: 3%;
  right: 0;
  z-index: 9000;
  text-align: center;
  border-radius: 50px;
  width: 150px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 15px;
  border: 2px solid black;
  font-weight: 300;
  font-size: 1.5rem;
  transform: translateY(0);
  box-shadow: 0px 0px 5px #d6d6d6;
  /* white theme */
  background: #333333;
  color: white;
  transition: color 0.5s, background 0.5s, transform 0.5s, font-weight 0.2s,
    box-shadow 0.5s;
}

.ready:hover {
  box-shadow: 0px 10px 10px #d6d6d6;
  background: white;
  color: #333333;
  transform: translateY(-10px);
  font-weight: 400;
}

.fake-bg {
  background: #f8f8f8;
  position: relative;
  z-index: 9000;
}

.card-section {
  background: #ffffff;
  -webkit-box-shadow: 0 30px 50px 0 rgba(1, 1, 1, 0.15);
  box-shadow: 0 30px 50px 0 rgba(1, 1, 1, 0.15);
  max-width: 1170px;
  margin: 0 auto 10rem;
  width: 95%;
  padding: 12rem 15rem;
  padding-bottom: 5rem;
}

.fake-bg .card-section:first-child {
  top: -10rem;
  position: relative;
  z-index: 900;
  margin-bottom: 0;
}

.fake-bg .card-section:first-child {
  top: -99px;
}

.section-content {
  max-width: 720px;
  margin: 0 auto 12rem;
}

.card-section > :last-child {
  margin-bottom: 0;
}

.card-section:first-child .section-content,
.section-content-page,
.section-content.section-content-a {
  max-width: 720px;
}

article {
  padding-bottom: 0;
  margin-bottom: 0;
  width: 100%;
}

.entry-header {
  position: relative;
  z-index: 2;
  margin-bottom: 2rem;
}

.entry-title {
  word-wrap: break-word;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #333333;
  font-size: 4rem;
  font-weight: 600;
}

.post-letter {
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.07);
  z-index: 1;
  position: absolute;
  top: 0%;
  left: -9rem;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  font-size: 27rem;
  font-weight: 600;
}

.entry-content {
  font-size: 1.5rem;
}

.info_text {
  position: relative;
  text-align: center;
  color: #c5c5c5;
  font-size: 16px;
  letter-spacing: 3px;
  word-spacing: 5px;
}

button {
  color: var(--text-color) !important;
}

.list-group .list-group-item:hover {
  background-color: #efefef;
}

.modal {
  z-index: 9999 !important;
}

.close {
  font-size: 2.3rem !important;
}

.modal-body {
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
  .ready {
    left: 8%;
    top: 48%;
  }
  .card-section {
    padding: 5rem;
  }
  .entry-content {
    font-size: 0.5rem;
  }
  .post-letter {
    left: -5rem;
    font-size: 16rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .entry-content {
    font-size: 1rem;
  }
  .post-letter {
    font-size: 26rem;
    top: 0%;
    left: -8rem;
  }
}

@media only screen and (max-width: 800px) {
  .ready {
    left: 4%;
    top: 48%;
  }
  label {
    font-size: 16px !important;
  }
  h1 {
    font-size: 28px !important;
  }
  h2 {
    font-size: 26px !important;
  }
  /* button, */
  .quit_btn,
  .same_font {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }

  .top_right_menu {
    font-size: 12px !important;
    padding: 5px 0px !important;
  }

  .os-phrases h2 {
    left: 2%;
  }
}

@media (max-width: 1260px) {
  .card-section {
    width: 93%;
  }
}

.border_container {
  border: 1px solid gray;
  padding: 15px !important;
}

.bottom_message {
  position: fixed;
  z-index: 5;
  bottom: 10px;
  left: 0;
  right: 0;
}

.fullscreen {
  position: fixed;
  right: 15px;
  width: 32px;
  bottom: 15px;
  cursor: pointer;
  transform: scale(0.7);
  z-index: 9999;
  transition: 0.5s transform, 0.5s filter;
}

.fullscreen:hover {
  transform: scale(0.9);
}

.skip {
  position: fixed !important;
  bottom: -20px !important;
  left: 15px !important;
  z-index: 9999 !important;
}
/* Editor css */

.editor-container {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.editor {
  border: 1px solid #333333;
}

.active_links {
  padding: 5px 20px;
  border-bottom: 2px solid #333333;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: gray !important;
  background-color: white !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #333333 !important;
  background-color: #333333 !important;
}

.alert-dark {
  background-color: #333333 !important;
  color: white !important;
}

.alert-dark .close {
  line-height: 0.8 !important;
}

.social_btn {
  border-radius: 50%;
  padding: 12px 13px;
  text-align: center;
  color: white;
  box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 0.527);
  transform: translateY(0px);
  transition: color 0.5s, background-color 0.5s;
}

.social_btn:hover {
  color: #333333;
  background-color: white;
}

.social_btn i {
  height: 15px;
  width: 15px;
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .navbar {
    opacity: 1 !important;
  }
  .left_count h2,
  .right_count h2,
  .right_count div,
  .left_count div {
    font-size: 16px !important;
    font-weight: bold;
  }
  .left_count div label,
  .right_count div label {
    font-size: 12px !important;
  }
}

.css-1nq8m5d,
.css-17l4ncr {
  margin: 10px 0;
}

.Toastify__toast-container.Toastify__toast-container--bottom-left {
  width: auto !important;
}

.Toastify__toast--dark {
  background: #333333;
  color: white !important;
  border-radius: 10px !important;
  height: auto !important;
}

.Toastify__toast--dark .Toastify__toast-body a {
  color: #b60808 !important;
  text-decoration: underline;
}

button.Toastify__close-button.Toastify__close-button--dark {
  color: white !important;
}

.cookie {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333333e3;
  color: white;
  padding: 20px;
  padding-bottom: 0;
}

.cookie a {
  text-decoration: none;
  color: white;
}

.cookie span {
  cursor: pointer;
  font-size: medium !important;
  border: 1px solid white;
  padding: 10px;
  margin-right: 10px;
}

.cookie p {
  font-size: medium !important;
  text-align: center;
}

.cookie p i {
  position: absolute;
  top: 10px;
  right: 10px;
}

.no_border {
  border: none !important;
}

@media screen and (max-width: 500px) {
  .cookie span,
  .cookie p {
    font-size: small !important;
  }
  .cookie span {
    margin: 0px;
    line-height: 1px;
    line-height: 2.4 !important;
  }
}

.button {
  color: var(--bgColor) !important;
}
