@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500&display=swap");
* {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

th {
  font-weight: bold !important;
}

body {
  overflow-x: hidden;
}

.btn-black {
  border-color: black !important;
}

.non-active {
  background-color: var(--bgColor);
  color: var(--text-color);
}

.non-active::before {
  background-color: var(--bgColor) !important;
  color: var(--text-color) !important;
}

.btn-black::before {
  transition: all 0.3s ease-in-out;
}

button {
  outline: none;
  overflow: hidden;
}
