.bodyColor {
  background-color: var(--text-color);
}

.dark-bg {
  background-color: var(--text-color) !important;
}

.mainTitle {
  font-size: 5.4rem;
  font-weight: 900;
  color: var(--text-color);
  line-height: 0.9;
}

.tagline {
  font-size: 16px;
  letter-spacing: 2px;
  margin: 0;
}

.auhter {
  font-size: 14px;
  text-align: end;
  font-style: italic;
}

/* Max-Width 800px*/
@media (max-width: 800px) {
  .mainTitle {
    font-size: 4rem !important;
    line-height: 0.8;
  }

  .tagline {
    font-size: 12px;
  }

  .auhter {
    font-size: 10px;
  }

  .dotLoader {
    width: 40px;
    height: 40px;
  }
}

.dotLoader.reversed {
  background-color: var(--bgColor);
  color: var(--text-color);
}

.flash-logo-text {
  z-index: 100;
  font-size: 3rem;
  font-weight: 900;
}

.fade-out {
  animation: fadeOut 2s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
