* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --text-color: #000;
}

body {
  font-family: "Poppins", sans-serif !important;
  color: var(--text-color);
}

.bodyColor {
  transition: all 0.3s ease-in-out;
  background-color: var(--bgColor) !important;
}

.text-bodyColor {
  color: var(--bgColor) !important;
}

// Scrollbar
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--bgColor);
}

*::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  &::hover {
    background: #555;
  }
}

textarea::placeholder {
  color: var(--text-color) !important;
  opacity: 1 !important;
  transition: all 0.2s;
}

.MuiTooltip-arrow::before {
  background-color: var(--text-color) !important;
}

textarea:focus::placeholder {
  opacity: 0 !important;
}

button {
  transition: all 0.3s ease-in-out;

  &:active {
    outline: none;
    transform: scale(0.99) translateY(1px);
  }
}

.utility-button-wrapper {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

// TypoGraphy
.heading {
  font-size: 2.5rem;
  line-height: 1.4;
  font-weight: 900;
  margin: 0;
}

.fs-12px {
  font-size: 0.8rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dotLoader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: var(--size);
  min-height: var(--size);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0px 5px;
  aspect-ratio: 1/1;

  &::before {
    content: attr(data-title);
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    // background-color: var(--text-color);
    // color: var(--bgColor);
    background-color: var(--bgColor);
    color: var(--text-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
    font-style: italic;
    font-size: 2rem;
  }

  &.dotLoaderTwoLine {
    &::before {
      content: attr(data-title) "\A to start";
    }
  }

  &.blinkAnimation {
    animation: dotAnimation 1s 0.3s alternate infinite;
    -webkit-animation: dotAnimation 1s 0.3s alternate infinite;
    // &::before {
    // }
  }
}

@-webkit-keyframes dotAnimation {
  0% {
    transform: scaleY(1);
  }

  15% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }

  85% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes dotAnimation {
  0% {
    transform: scaleY(1);
  }

  40% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }

  60% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(1);
  }
}

input {
  -webkit-text-fill-color: initial;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -moz-transition: background-color 5000s ease-in-out 0s;
  -ms-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;

  &:-webkit-autofill {
    @extend input;

    &:hover {
      @extend input;
    }

    &:focus {
      @extend input;
    }
  }
}

.inputFiled {
  border: 2px solid transparent !important;
  box-shadow: none !important;
}

.custom-input {
  padding: 10px 20px;

  &:disabled {
    opacity: 0.5;
  }

  &::-webkit-input-placeholder {
    color: inherit;
  }

  &::-moz-placeholder {
    color: inherit;
  }

  &:-ms-input-placeholder {
    color: inherit;
  }

  &::-ms-input-placeholder {
    color: inherit;
  }

  &::placeholder {
    color: inherit;
  }
}

.custom-select {
  height: 42px;

  &:disabled {
    opacity: 0.5;
  }
}

.same_font {
  font-size: 14px;
  color: #333333;
}

.noselect {
  -khtml-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.target-span {
  height: 2px;
  opacity: 1;
  display: inline-block;
  min-width: 300px;
  font-size: 1.5rem;
  background-color: var(--text-color);
  color: var(--text-color);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  margin: 0 auto;
  transform: translateY(-20px);
  max-width: 100%;
}

.help-field>.target-span {
  transform: translateY(-5px);
}

.inputFiled:focus~.target-span,
.help-field>.target-span {
  opacity: 0 !important;
}

.top-bar>*,
.top-bar>*>* {
  font-size: 0.9rem !important;
}

// Editor
.editor {
  border: 1px solid var(--text-color);
  background-color: var(--bgColor);
  color: var(--text-color);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    &.left-border-only {
      border: none;
      border-left: 1px solid var(--text-color);
    }
  }

  @media screen and (max-width: 768px) {
    &.left-border-only {
      border: none;
      border-top: 1px solid var(--text-color);
    }

    &.border-right-only {
      border-left: 1px solid var(--text-color);
    }
  }

  .CodeMirror {
    background-color: var(--bgColor);

    .CodeMirror-gutters {
      background-color: var(--bgColor);
      border-color: initial;
    }

    .CodeMirror-linenumber {
      color: inherit;
    }
  }
}

.btn-black {
  color: var(--bgColor) !important;
}

// .btn-black,
// .btn-dark {
//   padding: 15px 30px;
//   letter-spacing: 1px;
//   font-size: 1rem;
// }

.btn-black {
  position: relative;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: transparent !important;
  background-color: transparent !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;

  &:hover {
    box-shadow: none;
  }

  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: attr(data-title);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--text-color);
    color: var(--bgColor);
    // box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 10%);
  }

  &.blinkAnimation {
    background-color: transparent !important;
    animation: ButtonAnimation 0.3s 0s alternate 1;
    -webkit-animation: ButtonAnimation 0.3s 0s alternate 1;
    box-shadow: none !important;
  }

  &.quickBlink {
    background-color: transparent !important;
    animation: ButtonAnimation 0.3s 0s alternate 1;
    -webkit-animation: ButtonAnimation 0.3s 0s alternate 1;
    box-shadow: none !important;
  }
}

.blinkAnimation {
  background-color: transparent !important;
  animation: ButtonAnimation 0.3s 0s alternate 1;
  -webkit-animation: ButtonAnimation 0.3s 0s alternate 1;
  box-shadow: none !important;
}

@-webkit-keyframes ButtonAnimation {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes ButtonAnimation {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.is-reppling {
  display: none !important;
}

.modal-link {
  color: var(--text-color);
  text-decoration: none;
  position: relative;

  &:hover {
    color: var(--text-color);
    text-decoration: none;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 0%;
    height: 1.5px;
    background-color: var(--text-color);
    transition: all 0.5s ease-in-out;
  }

  &:hover::before {
    width: 100%;
  }
}

.icon-link:hover+.modal-link::before {
  width: 100% !important;
}

.bodyColor.animate {
  background-color: black !important;

  * {
    color: var(--bgColor) !important;
  }

  .text-dark {
    color: var(--text-color) !important;
  }

  .icon-dark {
    background-color: var(--text-color) !important;
  }
}

.presentIt {
  padding: 10px 20px;
  width: 150px;
  margin-inline: auto;
  cursor: pointer;
  text-align: center;
}

.fadeAnimation {
  animation: mymove 0.9s;
}

@keyframes mymove {
  from {
    background-color: var(--text-color);
  }

  to {
    background-color: var(--bgColor);
  }
}

// slides
.slideHeading {
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  // font-style: italic;
}

.reverseBTN {
  cursor: pointer;
}

// icons button

.iconButton {
  background-color: var(--text-color);
  border: 1px solid var(--bgColor);
  border-radius: 9px;
  height: 48px;
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  margin-inline: 5px;
  margin-block: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .iconButtonText {
    font-size: 1rem;
    vertical-align: center;
    color: var(--bgColor) !important;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
  }
}

.floatingButton {
  position: absolute;
  top: 15px;
  left: 15px;

  .circular-icons {
    // background-color: var(--bgColor) !important;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login {
  background: var(--bgColor);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .background {
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  .background .shape {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
  }

  .shape:first-child {
    background: linear-gradient(#1845ad, #23a2f6);
    left: 0px;
    top: 0px;
  }

  .shape:last-child {
    background: linear-gradient(to right, #ff512f, #f09819);
    right: 0px;
    bottom: 50px;
  }

  form {
    min-height: 520px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 25px 25px;
    position: relative;
  }

  form * {
    font-family: "Poppins", sans-serif;
    color: var(--text-color);
    letter-spacing: 0.5px;
    outline: none;
    border: none;
  }

  form h4 {
    font-weight: 800;
    text-align: center;
    font-size: 24px;
  }

  label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
  }

  ::placeholder {
    color: #222;
  }

  button {
    margin-top: 20px;
    width: 100%;
    background-color: var(--text-color);
    color: var(--bgColor);
    padding: 15px 0;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }

  .social {
    margin-top: 30px;
    display: flex;
  }
}

.login-wrapper {
  height: 100vh !important;
}

.text-dark {
  color: var(--text-color) !important;
}

.bg-dark {
  background-color: var(--text-color) !important;
}

.icon-dark {
  background-color: var(--bgColor) !important;
}

.border-dark {
  border-color: var(--text-color) !important;
}

.btn-dark {
  color: #fff;
  background-color: var(--text-color) !important;
  border-color: var(--text-color) !important;
}

.btn-dark:hover {
  color: #fff;
  background-color: var(--text-color) !important;
  border-color: var(--text-color) !important;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-dark {
  color: var(--text-color) !important;
  border-color: var(--text-color) !important;

  &:hover {
    color: var(--bgColor) !important;
    background-color: var(--text-color) !important;
    border-color: var(--text-color) !important;
  }
}

.pointer-cursor {
  cursor: pointer;
}

body>iframe {
  display: none !important;
}

.placeholder-styling::placeholder {
  opacity: 0.1;
  font-size: 4rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: var(--text-color);
}

.max-width-300px {
  max-width: 300px;
}

.max-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slides-action-buttons {
  .slides-action-button {
    margin-inline: 10px;
    padding: 2px 5px;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 100px;
  }
}

.basic-input {
  all: unset;
  outline: none !important;
  cursor: text;
}

.floatingButton {
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}

.floatingButton:hover {
  opacity: 1;
}

.editor {
  .CodeMirror-scroll {
    overflow: hidden !important;
  }

  &.max-scroll-height-300px {
    .CodeMirror {
      height: 100%;
      // height: 100% !important;
      min-height: 300px !important;
    }
  }

  @media screen and (min-width: 768px) {
    .CodeMirror {
      height: 100%;
      min-height: 300px;
    }
  }

  .CodeMirror-code {
    &>.CodeMirror-line {
      &:nth-child(even) {
        background-color: rgb(217, 217, 217, 0.2);
      }
    }

    div {
      &:nth-child(even) {
        background-color: rgb(217, 217, 217, 0.2);

        .CodeMirror-linenumber {
          background-color: rgb(217, 217, 217, 0.2);
        }
      }
    }
  }
}

.bgColor-black-20 {
  background-color: rgb(217, 217, 217, 0.2);
}

.max-h-300px {
  height: 100%;
  max-height: 300px;
}

.z-index-50 {
  z-index: 50;
}

.delete-dropdown {
  &::after {
    display: none !important;
  }

  +.dropdown-menu {
    background-color: var(--bgColor);
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    font-weight: 500;
    border: 1px solid var(--text-color);
    padding: 0;
    width: auto;
    min-width: auto;

    .dropdown-item {
      color: var(--text-color);
      background-color: transparent;
    }
  }
}

.circle-box {
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1/1;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bgColor);
  border: none;
  box-shadow: none;
  color: var(--text-color) !important;
  font-size: 1rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-weight: 600;

  &:hover {
    background-color: var(--text-color);
    color: var(--bgColor) !important;
    border: 1px solid var(--bgColor);
  }
}

.bodyColor {
  .circle-box {
    color: var(--text-color) !important;

    &:hover {
      color: var(--bgColor) !important;
    }
  }
}

.w-50px {
  // width: 50px !important;
  min-width: 50px !important;
}

.icon-rounded-32px {
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hover-opacity {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.progress-bar-container {
  overflow: hidden;

  .progress-bar-custom {
    height: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    &.progress {
      border-radius: 2rem;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      -ms-border-radius: 2rem;
      -o-border-radius: 2rem;
      position: relative;
      background-color: transparent;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--bgColor);
        border-radius: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
      }
    }

    .progress-bar {
      text-align: right;
      border-radius: 2rem;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      -ms-border-radius: 2rem;
      -o-border-radius: 2rem;
      // padding-right: 0.5rem;
      background-color: var(--bgColor);
      color: var(--text-color) !important;
      position: relative;
      overflow: visible;

      &::before {
        content: attr(aria-valuenow) "%";
        position: absolute;
        left: calc(100% + 0.5rem);
        align-items: center;
        color: var(--bgColor);
        top: 0;
      }
    }
  }

  .progress-text {
    font-size: 0.8rem;
    opacity: 0;
    visibility: hidden;
    right: 0.75rem;
  }

  &:hover {
    .progress {
      height: 1rem;
    }

    .progress-text {
      opacity: 1;
      visibility: visible;
    }
  }
}

@media (min-width: 576px) {
  .sliding-navbar {
    opacity: 0.5;
    transition: 1s all ease-in-out;
    -webkit-transition: 1s all ease-in-out;
    -moz-transition: 1s all ease-in-out;
    -ms-transition: 1s all ease-in-out;
    -o-transition: 1s all ease-in-out;
    margin-top: -50px;
    top: -50px;

    .nav-item {
      opacity: 0;
      visibility: hidden;
      transition: 1s all ease-in-out;
      -webkit-transition: 1s all ease-in-out;
      -moz-transition: 1s all ease-in-out;
      -ms-transition: 1s all ease-in-out;
      -o-transition: 1s all ease-in-out;
    }

    &:hover {
      margin-top: 0;
      top: 0;

      .nav-item {
        opacity: 1;
        visibility: visible;
      }

      opacity: 1;
    }
  }
}

.hover-fade-amination {
  opacity: 0.1;

  &.opacity-20 {
    opacity: 0.3;
  }

  transition: 0.5s all ease-in;
  -webkit-transition: 0.5s all ease-in;
  -moz-transition: 0.5s all ease-in;
  -ms-transition: 0.5s all ease-in;
  -o-transition: 0.5s all ease-in;

  &:hover {
    opacity: 1;
  }
}


//quiz page 
.quiz_start_mian {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quiz_start_sec_1 {
  display: flex;
  flex-direction: row;
}

.text_A {
  text-align: center;
  font-size: 5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.01525rem;
}

.quiz_btn {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 5rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.01525rem;
  padding: 0;
  margin: 0;
  width: 17.7rem;
  /* Set a fixed width */
  text-align: center;
}

.selected_btn {
  font-weight: 700;
}

.quiz_start_sec_2 {
  width: 100%;
}

.quiz_start_sec_3 {}

//quiz starrted page 
.partB_input {
  margin-top: -1rem !important;


}

.partB_input:focus {

  outline: none !important;
  box-shadow: none;


}

.partB_input::placeholder {
  color: var(--bgColor) !important;
  opacity: 1;
  font-size: 5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.01525rem;
}

.dots-container {
  margin-top: -5.5rem;
}

.dot {
  display: inline-block;
  transition: transform 0.6s;
}

.bounce {
  animation: bounce 0.6s ease-in-out infinite;
}

.dot-0 {
  animation-delay: 0.25s;
}

.dot-1 {
  animation-delay: 0.5s;
}

.dot-2 {
  animation-delay: 0.75s;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }
}

//answer status 
.ans_status {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 3rem;
  margin-bottom: -2rem;
  letter-spacing: -0.01525rem;

  .ans_text {
    margin: 0;
    padding: 0;
  }
}
.last-line-bg {
  background-color: black;
}

.last-line-text {
  color: var(--bgColor) !important;
}

// .CodeMirror-cursor {
//   border-left: 1px solid var(--text-color);
// }

.last-line-bg  {
  border-left: 1px solid var(--bgColor);
}