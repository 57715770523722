.buttonBox{
    justify-content: center !important;
    align-items: center;
}
.instructionText{
    font-size: 16px;
    width: 300px;  
    text-align: center;  
}
.success_btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

/* textarea {
    border: 1px solid gray !important;
    padding: 5% !important;
    box-shadow:  none !important;
}

textarea:not(:focus) {
    color: lightgray
} */

@media screen and (max-width: 650px) {

    .textarea_container {
        margin: 10px 0;
        margin-top: 50px;
    }
}
/* reversed */
.numbersSelectBox{
    border:1px solid var(--text-color);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.numbersSelectBox > div{
    height: 41px;
    width: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease-in-out;

    /* margin-inline: 50px; */
}
.numberDesBox{
    /* border:1px solid var(--text-color); */
    border-radius: 5px;
    height: 30px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.numberDesBoxActive{
    border:1px solid var(--bgColor);
    color: var(--bgColor);
    background-color: var(--text-color);
    border-radius: 5px;
    margin-inline: 15%;
    height: 41px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.otherProjects{
    border:1px solid var(--text-color);
    border-radius: 5px;
    /* margin-inline: 15%; */
    text-align: center;
 width: 100%    ;
    height: 86px;
    font-weight: 300;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}